import {memo, useEffect, useState} from "react";
import apiCall from "../base/call";
import {cleanDataSchema, heatMapSchema} from "../../data/chartSchemas";
import TabsContainer from "../base/Tabs";
import {convertToArrMatrix} from "../../utils/dataTransforms";
import HeatMap from "../charts/HeatMapChart";


const PurchaseBehaviour = memo(({setAlert, filters, activeTab, auth}) => {
    const [txDayData, setDayData] = useState(heatMapSchema);
    const [txWeekData, setWeekData] = useState(heatMapSchema);
    const [txMonthData, setMonthData] = useState(heatMapSchema);

    const [lastTab, setLastTab] = useState(0)
    const [activeTabInner, setActiveTabInner] = useState([])

    useEffect(() => {
        console.log('call PC1')
        if (!activeTab) return

        setDayData(cleanDataSchema(heatMapSchema))
        setWeekData(cleanDataSchema(heatMapSchema))
        setMonthData(cleanDataSchema(heatMapSchema))
        apiCall(setAlert, 'rpc/sp_day_range_comparison', filters, auth).then(resp => {
            if (resp && resp.data.length > 0) {
                let yAxis = resp.data.map(x => x._period)
                let xAxis = (Object.keys(resp.data[0])).slice(1)
                let series = convertToArrMatrix(resp.data, xAxis)

                let dailySales = {
                    xAxis: ['Daily Sales', 'Prev Day Sales', 'Prev Week Sales', 'Prev Month Sales', 'Prev Year Sales'],
                    yAxis: yAxis,
                    series: series
                }
                setDayData(dailySales)
            }
        });

        apiCall(setAlert, 'rpc/sp_week_range_comparison', filters, auth).then(resp => {
            if (resp && resp.data.length > 0) {
                let yAxis = resp.data.map( x => (x._period + ' – ' + x._week_no) )
                let xAxis = ( Object.keys(resp.data[0]) ).slice(2)
                let series = convertToArrMatrix(resp.data, xAxis)

                let weeklySales = {
                    xAxis: ['Weekly Sales', 'Prev Week Sales', 'Prev Month Sales', 'Prev Year Sales'],
                    yAxis: yAxis,
                    series: series
                }
                setWeekData(weeklySales)
            }
        });

        apiCall(setAlert, 'rpc/sp_month_range_comparison', filters, auth).then(resp => {
            if (resp && resp.data.length > 0) {
                let yAxis = resp.data.map(x => x._period)
                let xAxis = ( Object.keys(resp.data[0]) ).slice(1)
                let series = convertToArrMatrix(resp.data, xAxis)

                let monthlySales = {
                    xAxis: ['Monthly Sales', 'Prev Month Sales', 'Prev Year Sales'],
                    yAxis: yAxis,
                    series: series
                }
                setMonthData(monthlySales)
            }
        });

        console.log('call PC2')
    }, [activeTab, filters.filter])


    if (!activeTab) return null
    console.log('rendering PC')
    return(
        <div className="mt-8">
            <TabsContainer activeTab={activeTabInner} setLastTab={setLastTab} setActiveTab={setActiveTabInner}>
                {/*<h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">In-Store</h2>*/}
                <HeatMap name="Daily Sales HeatMap"
                         title={{text: 'Day-over-Day Comparison'}}
                         xAxis={txDayData.xAxis}
                         yAxis={txDayData.yAxis}
                         data={txDayData.series}
                         isActive={lastTab === 0}/>
                <HeatMap name="Weekly Sales HeatMap"
                         title={{text: 'Week-over-Week Comparison'}}
                         xAxis={txWeekData.xAxis}
                         yAxis={txWeekData.yAxis}
                         data={txWeekData.series}
                         isActive={lastTab === 1}/>
                <HeatMap name="Monthly Sales HeatMap"
                         title={{text: 'Month-over-Month Comparison'}}
                         xAxis={txMonthData.xAxis}
                         yAxis={txMonthData.yAxis}
                         data={txMonthData.series}
                         isActive={lastTab === 2}/>
            </TabsContainer>

        </div>
    )
});

export default PurchaseBehaviour