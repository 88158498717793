import {Paragraphs} from "../../../data/options";
import React, {useEffect, useState} from "react";
import {Summary} from "./Summary";
import TabsContainer from "../../base/Tabs";
import {GenericReport} from "./GenericReport";
import apiCall from "../../base/call";
import {OrderExport} from "./OrderExport";

export const InventoryReports = ({setAlert, filters, auth, selectedProducts}) => {
    // Uploads all the data required for tabs. No post-processing
    const [lastTab, setLastTab] = useState(0)
    const [activeTab, setActiveTab] = useState([])

    const [baseStats, setBaseStats] = useState()
    const [generalData, setGeneralData] = useState(null)

    const [batchProducts, setBatchProducts] = useState([])
    const [batchData, setBatchData] = useState(null)

    const [discountProducts, setDiscountProducts] = useState([])
    const [extraData, setExtraData] = useState(null)
    const [discountData, setDiscountData] = useState(null)

    useEffect(() => {
        console.log('reloading InvContainer')
        setActiveTab([lastTab])
    }, [])

    useEffect(() => {
        console.log('cleaning InvContainer data')
        setActiveTab([lastTab])
        setBaseStats(null)
        setBatchProducts([])
        setDiscountProducts([])
        setGeneralData('loading')
        setDiscountData('loading')
        setBatchData('loading')
        setExtraData('loading')
    }, [filters.filter])

    const handleFilterClick = (e) => {
        e.preventDefault()
        setActiveTab([lastTab])
        setBaseStats('loading')
        setGeneralData('loading')
        setDiscountData('loading')
        setBatchData('loading')
        setExtraData('loading')
        console.log('resetting active tab state to', lastTab)

        // moved selected products out of filters state, to prevent false trigger memo components above(brands graphs)
        const selected_pid = selectedProducts.map(idx => idx['_product_id']).join()
        const callFilter = {...filters, ['selected_pid']: selected_pid}
        apiCall(setAlert, 'rpc/overview_sales_profit_revenue', callFilter, auth).then(resp => {
            if (resp) setBaseStats(resp.data)
        })

        apiCall(setAlert, 'rpc/inv_brand_category_report', callFilter, auth).then(resp => {
            if (resp) setGeneralData(resp.data)
        })

        apiCall(setAlert, 'rpc/inv_selected_batch_pid', callFilter, auth).then(resp => {
            if (resp) setBatchProducts(resp.data)
        })
        apiCall(setAlert, 'rpc/inv_batch_report', callFilter, auth).then(resp => {
            if (resp) setBatchData(resp.data)
        })

        apiCall(setAlert, 'rpc/inv_selected_discount_pid', callFilter, auth).then(resp => {
            if (resp) {
                console.log('inv_selected_discount_pid FINISHED', resp)
                setDiscountProducts(resp.data)
            }
        })
        apiCall(setAlert, 'rpc/inv_discounts_report', callFilter, auth).then(resp => {
            if (resp) setDiscountData(resp.data)
        })
        apiCall(setAlert, 'rpc/inv_discount_distribution_report', callFilter, auth).then(resp => {
            if (resp) setExtraData(resp.data)
        })
    }

    console.log('rendering INV REPORTS')
    return (
        <div className="h-lvh m-2 mt-8">
            <div className="flex flex-row items-center justify-between mt-4">
                <p className='font-sans pl-2 inline-block text-2xl sm:text-2xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {Paragraphs.REPORTS}
                </p>
                <button className="btn btn-sm max-w-xs" onClick={handleFilterClick}>Refresh Graphs</button>
            </div>
            <TabsContainer activeTab={activeTab} setLastTab={setLastTab} setActiveTab={setActiveTab}>
                <Summary name="Summary" activeTab={lastTab === 0} baseStats={baseStats} selectedProducts={selectedProducts} data={generalData}/>
                <GenericReport name="Categories" activeTab={lastTab === 1} mutation={'_master_category_name'} selectedProducts={selectedProducts} data={generalData}/>
                <GenericReport name="Subcategories" activeTab={lastTab === 2} mutation={'_category_name'} selectedProducts={selectedProducts} data={generalData}/>
                <GenericReport name="Brands" activeTab={lastTab === 3} mutation={'_brand_name'} selectedProducts={selectedProducts} data={generalData}/>
                <GenericReport name="Products" activeTab={lastTab === 4} mutation={'_product_name'} selectedProducts={selectedProducts} data={generalData}>
                    <OrderExport products={selectedProducts} filters={filters}/>
                </GenericReport>
                <GenericReport name="Discounts" activeTab={lastTab === 5} mutation={'_discount_name'} selectedProducts={discountProducts} data={discountData} extraData={extraData}/>
                <GenericReport name="By Batch" activeTab={lastTab === 6} mutation={'_batch_name'} selectedProducts={batchProducts} data={batchData}/>
            </TabsContainer>
        </div>
    )
}