import {useEffect, useState} from "react";
import {filterDefault, PageNames} from "../data/options";
import {TenantSelector} from "./base/Selector";
import {Stat} from "./dashboards/Stat";
import {
    cleanStatSchema,
    inventoryDashboardSchema,
    overviewDashboardSchema,
    overviewDataMap,
    statSchema,
    trendSchema
} from "../data/chartSchemas";
import apiCall from "./base/call";
import moment from "moment";
import {extractStatsArrangedByCol, prepareLabourStat} from "../utils/overviewPageTransforms";
import {InventoryOverview} from "./dashboards/InventoryOverview";
import {Link} from "react-router-dom";


export const OverviewPage = ({auth, setAlert}) => {
    const [data, setData] = useState(overviewDataMap)
    const [stats, setStats] = useState(cleanStatSchema(overviewDashboardSchema), false)
    const [filters, setFilters] = useState(filterDefault)

    // apply right filter range
    useEffect(() => {
        let start_date = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')
        let end_date = moment().format('YYYY-MM-DD')
        setFilters({...filters, ['start_date']: start_date, ['end_date']: end_date})
    }, [])

    // upload all the data
    const handleClick = () => {
        console.log('call OVERVIEW1')
        setStats(cleanStatSchema(overviewDashboardSchema, true))
        for (const [key, value] of Object.entries(data)) {
            apiCall(setAlert, value.endpoint, filters, auth).then(resp => {
                if (resp && resp.data.length > 0) {
                    value.data = resp.data
                    setData({...data})
                }
                else {
                    value.data = false
                    setData({...data})
                }
            })
        }
        setFilters({...filters, ['filter']: !filters.filter})
    }

    // single effect for a single data array
    useEffect(() => {
        console.log('Preparing of STATS')
        if (data.sales.data) {
            const stores = data.sales.data.length
            stats.stores = {...stats.stores, ['value']: stores, ['loading']: false}
            const netSales = extractStatsArrangedByCol(data.sales.data, '_net_sales', '_prev_net_sales')
            stats.netSales = {...stats.netSales, ...netSales, ['loading']: false}
            const ticket = extractStatsArrangedByCol(data.sales.data, '_avg_net_ticket', '_prev_avg_net_ticket')
            stats.ticket = {...stats.ticket, ...ticket, ['loading']: false}
            const transactions = extractStatsArrangedByCol(data.sales.data, '_transactions', '_prev_transactions')
            stats.transactions = {...stats.transactions, ...transactions, ['loading']: false}
            const customers = extractStatsArrangedByCol(data.sales.data, '_unique_customers', '_prev_unique_customers')
            stats.customers = {...stats.customers, ...customers, ['loading']: false}
            const labourRatio = prepareLabourStat(data.sales.data)
            stats.labourRatio = {...stats.labourRatio, ...labourRatio, ['loading']: false}
            // business has not defined the way we should calculate this
            stats.liabilityRatio = {...stats.liabilityRatio, ['loading']: false}

            const debtRatio = extractStatsArrangedByCol(data.sales.data, '_avg_debt', '_prev_avg_debt')
            stats.debtRatio = {...stats.debtRatio, ...debtRatio, ['loading']: false}
            stats.debtRatio.trend.unit = null
            stats.debtRatio.trend.direction = !stats.debtRatio.trend.direction
        }

        if (data.margin.data) {
            const margin = extractStatsArrangedByCol(data.margin.data, '_margin', '_prev_margin')
            stats.margin = {...stats.margin, ...margin, ['loading']: false}
            const marginPct = extractStatsArrangedByCol(data.margin.data, '_margin_pct', '_prev_margin_pct')
            stats.marginPct = {...stats.marginPct, ...marginPct, ['loading']: false}
        }

        if (data.loyalCustomers.data) {
            const loyalCustomers = extractStatsArrangedByCol(data.loyalCustomers.data, '_curr_m_loyal', '_prev_m_loyal')
            stats.loyalCustomers = {...stats.loyalCustomers, ...loyalCustomers, ['loading']: false}
        }

        if (data.newCustomers.data) {
            const newCustomers = extractStatsArrangedByCol(data.newCustomers.data, '_new_customers', '_prev_new_customers')
            stats.newCustomers = {...stats.newCustomers, ...newCustomers, ['loading']: false}
        }

        if (data.lostCustomers.data) {
            const lostCustomers = extractStatsArrangedByCol(data.lostCustomers.data, '_lost_customers', '_prev_lost_customers')
            stats.lostCustomers = {...stats.lostCustomers, ...lostCustomers, ['loading']: false}
        }

        if (data.returnCustomers.data) {
            const returnedCustomers = extractStatsArrangedByCol(data.returnCustomers.data, '_avg_curr_month_hit', '_avg_prev_month_hit')
            stats.returnedCustomers = {...stats.returnedCustomers, ...returnedCustomers, ['loading']: false}
        }

        setStats({...stats})
    }, [data])

    console.log('OV render')
    return (
        <div className="max-w-[100vw] pb-16 xl:pr-2 m-4">
            <div className="flex flex-row items-center justify-between m-2 mt-8">
                <p className='font-sans pl-2 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.OVERVIEW.name}
                </p>
            </div>
            <div className="flex flex-row items-center">
                <TenantSelector state={filters} setState={setFilters} setAlert={setAlert} auth={auth} allTenants name='tenant_id'/>
                <button className="btn btn-sm w-sm max-w-xs" onClick={handleClick}>Show Statistics</button>
            </div>

            <div className="flex flex-row items-center justify-between m-2 mt-8">
            <p className='font-sans pl-2 inline-block text-2xl sm:text-2xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.PERFORMANCE.name}
                </p>
                <Link to={PageNames.PERFORMANCE.link}>
                    <button className="btn btn-sm w-full max-w-xs">See Details</button>
                </Link>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 m-2 stats stats-vertical shadow">
                <Stat {...stats.stores} />
                <Stat {...stats.netSales}/>
                <Stat {...stats.transactions}/>
                <Stat {...stats.ticket}/>
                <Stat {...stats.margin}/>
                <Stat {...stats.marginPct}/>
            </div>

            <div className="flex flex-row items-center justify-between m-2 mt-8">
            <p className='font-sans pl-2 inline-block text-2xl sm:text-2xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.FINANCIAL.name}
                </p>
                <Link to={PageNames.FINANCIAL.link}>
                    <button className="btn btn-sm w-full max-w-xs">See Details</button>
                </Link>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 m-2 stats stats-vertical shadow">
                <Stat {...stats.debtRatio}/>
                <Stat {...stats.labourRatio}/>
                <Stat {...stats.liabilityRatio}/>
            </div>

            <div className="flex flex-row items-center justify-between m-2 mt-8">
                <p className='font-sans pl-2 inline-block text-2xl sm:text-2xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.CUSTOMER.name}
                </p>
                <Link to={PageNames.CUSTOMER.link}>
                    <button className="btn btn-sm w-full max-w-xs">See Details</button>
                </Link>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-2 m-2 mb-4 stats stats-vertical shadow">
                <Stat {...stats.customers}/>
                <Stat {...stats.returnedCustomers}/>
            </div>
            <div className="grid lg:grid-cols-3 grid-cols-2 m-2 stats stats-vertical shadow">
                <Stat {...stats.loyalCustomers}/>
                <Stat {...stats.newCustomers}/>
                <Stat {...stats.lostCustomers}/>
            </div>

            <InventoryOverview setAlert={setAlert} filters={filters} auth={auth}/>
        </div>
    )
}
