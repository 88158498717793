import {AlertTypes, filterDefault, PageNames, Paragraphs} from "../data/options";
import {Filter} from "./base/Filter";
import {TenantSelector} from "./base/Selector";
import React, {useEffect, useState} from "react";
import apiCall, {getApiCal} from "./base/call";
import {InventoryReports} from "./dashboards/inventoryReports/InventoryReports"
import BrandSales from "./dashboards/BrandSales";
import {InventoryOverview} from "./dashboards/InventoryOverview";
import {Table} from "./charts/Table";
import {colNamingLambda, colVisibilityFilter} from "../utils/tableLambdas";
import {formatString, undefinedRoom} from "../data/messages";


export const InventoryPage = ({auth, setAlert}) => {
    const [filters, setFilters] = useState(filterDefault)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const [selectedProducts, setSelectedProducts] = useState([])

    useEffect(() => {
        const request = `room_view?select=room_name&tenant_id=eq.${filters.tenant_id}&for_sale=is.null`
        getApiCal(setAlert, request, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                if (resp.data && resp.data.length > 0) {
                    const roomNames = resp.data.map(x => x.room_name)
                    let msg = formatString(undefinedRoom, {rooms: roomNames})
                    let alert = {type: AlertTypes.WARNING, msg: msg, visible: true}
                    setAlert(alert)
                } else {
                    setAlert({visible: false})
                }
            }
        })
    }, [filters.tenant_id])

    useEffect(() => {
        const selection  = Object.keys(rowSelection).map(idx => data[idx])
        setSelectedProducts(selection)
    }, [rowSelection])

    const handleFilterClick = (e) => {
        e.preventDefault()
        setFilters({...filters, ['filter']: !filters.filter})
        setData([])
        setRowSelection({})
        setLoading(true)
        apiCall(setAlert, 'rpc/inv_all_products', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                setLoading(false)
                setData(dt)
            }
        })
    }

    console.log('rendering INV PAGE')
    return (
        <div className="max-w-[100vw] pb-16 xl:pr-2 m-4">
            <div className="flex flex-row items-center justify-between mb-4">
                <p className='font-sans pl-4 mt-2 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.INVENTORY.name}
                </p>
                <Filter filters={filters} setFilters={setFilters}/>
            </div>
            <div className="flex flex-row items-center justify-between ">
                <TenantSelector state={filters} setState={setFilters} setAlert={setAlert} auth={auth} name='tenant_id'/>
                <button className="btn btn-sm w-full max-w-xs" onClick={handleFilterClick}>Filter</button>
            </div>
            <InventoryOverview setAlert={setAlert} filters={filters} auth={auth} optionalName={Paragraphs.OVERVIEW}/>
            <div className="m-2 mt-8">
                <BrandSales setAlert={setAlert} filters={filters} activeTab={true} auth={auth}/>
                <Table data={data} filtering={true} loading={loading}
                       rowSelection={rowSelection} setRowSelection={setRowSelection}
                       colVisibilityFilter={colVisibilityFilter} colNamingLambda={colNamingLambda}/>

            </div>
            <InventoryReports setAlert={setAlert} filters={filters} auth={auth} selectedProducts={selectedProducts}/>
        </div>
    )
}


