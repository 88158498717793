import axios from "axios";
import {AlertTypes} from "../../data/options";
import Auth from "./Auth";


const baseURL = () => {
    if (window.location.hostname === 'dev.stables.palomino.consulting') return 'https://api.stables.palomino.consulting'
    if (window.location.hostname === 'localhost') return 'https://api.stables.palomino.consulting'
    return `${window.location.protocol}//api.${window.location.hostname}`
    }


const apiCall = (setAlert, endpoint, filters, auth = Auth) => {
    console.log('Calling endpoint, filters', endpoint, filters)
    const url = baseURL()
    const nginx_url =  `${url}/api/${endpoint}`
    let header = {headers: {'Authorization': 'Bearer ' + auth.cls.jwtToken}}
    return axios.post(nginx_url, filters, header).catch(error => {
            let msg = error.message
            if (error.message === 'Network Error') msg = 'Too many data points to process.'
            let alert = {type: AlertTypes.ERROR, msg: error.message, visible: true}
            console.log('Request Error:', error)
            setAlert(alert)
        });
}


const getApiCal = (setAlert, request, auth = Auth) => {
    console.log('Calling request:', request)
    const url = baseURL()
    const nginx_url =  `${url}/api/${request}`
    let header = {headers: {'Authorization': 'Bearer ' + auth.cls.jwtToken}}
    return axios.get(nginx_url, header).catch(error => {
        let msg = error.message
        if (error.message === 'Network Error') msg = 'Too many data points to process.'
        let alert = {type: AlertTypes.ERROR, msg: error.message, visible: true}
        console.log('Request Error:', error)
        setAlert(alert)
    });
}


export {apiCall as default, baseURL, getApiCal}
