import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsBoost from "highcharts/modules/boost";
HighchartsBoost(Highcharts);


const PieChart = ({title, subtitle, data}) => {
    const options = {
        chart: {
            type: 'pie'
        },
        boost: {
            useGPUTranslations: true,
            // Chart-level boost when there are more than 20 series in the chart
            seriesThreshold: 20
        },
        title: title,
        subtitle: subtitle,
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: true,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '1em',
                        textOutline: 'none',
                        opacity: 0.6
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 5
                    }
                }]
            }
        },
        series: [
            {data: data.data}
        ]
    }
    // console.log('rendering pie chart', title)
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
};

export default PieChart