const dataSchema = {
    yAxis:{},
    xAxis: {},
    series: [],
    count: 0
}

const txRelatedFields = {
    netSales: dataSchema,
    transactions: dataSchema,
    avgTicket: dataSchema,
    customers: dataSchema}

const invReportSummary = {
    unitsSold: dataSchema,
    marginPct: dataSchema
}

const discountBatchDetails = {
    discDistribution: dataSchema,
    batchDistribution: dataSchema
}

const itemRelatedFields = {
    singleItem: dataSchema,
    multiItem: dataSchema
}

const txCategoryFields = {
    mixUnits: dataSchema,
    mixRevenue: dataSchema,
    units: dataSchema,
    revenue: dataSchema
}

const txBehaviourFields = {
    revenueComparison: dataSchema,
    transactionsComparison: dataSchema,
    customersComparison: dataSchema
}

const registerBehaviourFields = {
    netSales: dataSchema,
    transactions: dataSchema,
    avgTicket: dataSchema,
    customers: dataSchema}

const cleanDataSchema = (dataObject) => {
    const resetObject = {};

    for (const key in dataObject) {
        if (typeof dataObject[key] === 'object' && !Array.isArray(dataObject[key])) {
            // If the value is an object, recursively reset it
            resetObject[key] = cleanDataSchema(dataObject[key]);
        } else if (Array.isArray(dataObject[key])) {
            resetObject[key] = [];
        } else {
            // Reset other primitive values to their defaults
            resetObject[key] = typeof dataObject[key] === 'number' ? 0 : null;
        }
    }

    return resetObject;
};

const shiftOptions = {'1h Shifts': 'rpc/sp_staff_1h_shifts', '3h Shifts': 'rpc/sp_staff_3h_shifts' , '4h Shifts': 'rpc/sp_staff_4h_shifts'}

const trendSchema = {points: 0, direction: false}
const statSchema = {main: null, trend: trendSchema}
const overviewDashboardSchema = {
    stores: {title:'Active Stores', description: 'Current month', value: null, trend: trendSchema},
    ticket: {title:'Avg Daily Net Ticket', unit: '$', value: null, trend: trendSchema},
    margin: {title:'Avg Margin', description: 'Avg Daily Margin, MoM', unit: '$', value: null, trend: trendSchema},
    marginPct: {title:'Avg Margin Pct', description: 'Avg Daily Margin Percent, MoM', unit: '%', value: null, trend: trendSchema},
    transactions: {title:'Avg Transactions', description: 'Daily Transactions, MoM', value: null, trend: trendSchema},
    netSales: {title:'Avg Net Sales', description: 'Daily Net Sales, MoM', unit: '$', value: null, trend: trendSchema},
    customers: {title:'Avg Customers', description: 'Unique Customers, MoM', value: null, trend: trendSchema},
    loyalCustomers: {title:'Avg Loyal Customer', description: 'Loyal Customers (shopped 6+ times in the last 180 days, and have also shopped in the last 30d), MoM', value: null, trend: trendSchema},
    newCustomers: {title:'Avg New Customers', description: 'New Customers (shopped first time this month, since previous 3 month absence), MoM', value: null, trend: trendSchema},
    lostCustomers: {title:'Avg Lost Customers', description: 'Lost Customers (Did not shopped this month, but was hitting previous 3 month), MoM', value: null, trend: trendSchema},
    returnedCustomers: {title:'Avg Customer Returns', description: 'Avg number of return for a single customer per month, MoM', value: null, trend: trendSchema},
    debtRatio: {title:'Avg Debt to Income Ratio', description: 'Avg daily Net Sales / Debt over Month period ', value: null, trend: trendSchema},
    labourRatio: {title:'Avg Staffing Cost VS Sales', description: '% of Avg Daily Staffing cost to Sales over Month period', unit: '%', value: null, trend: trendSchema},
    liabilityRatio: {title:'Avg Cash vs Liabilities', description: '', unit: '%', value: null, trend: trendSchema}
}

const inventoryDashboardSchema = {
    skuStock: {title:'Total In-Stock SKUs', description: 'Current qty of instock SKUs', value: null, trend: trendSchema},
    inventoryCost: {title:'Total Inventory Cost', description: 'Current cost of inventory in stock', unit: '$', value: null, trend: trendSchema},
    inventoryQty: {title:'Total Units in Stock', description: 'Current amount of inventory pieces in stock', unit: 'pcs', value: null, trend: trendSchema},
    burnRate: {title:'Avg Burn Rate', description: 'Amount of items sold per day. Based on current stock and monthly burn rate performance.', unit: 'pcs', value: null, trend: trendSchema},
    dailyCogsProjected: {title:'Avg Daily COGS Projected', description: 'Cost of good sold per day. Based on current stock and monthly burn rate performance. ', unit: '$', value: null, trend: trendSchema},
    dailyCogsObserved: {title:'Avg Daily COGS Observed', description: 'Cost of good sold per day. Based on current stock and monthly burn rate performance. ', unit: '$', value: null, trend: trendSchema},
    daysTurnoverProjected: {title:'Avg Days to Turnover Projected', description: 'Qty of days needed to turnover all inventory (cost speaking). Based on monthly performance', unit: 'days', value: null, trend: trendSchema},
    daysTurnoverObserved: {title:'Avg Days to Turnover Observed', description: 'Qty of days needed to turnover all inventory (cost speaking). Based on monthly performance', unit: 'days', value: null, trend: trendSchema},
    streetValue: {title:'Street Value', description: 'Sum of unit price', unit: '$', value: null, trend: trendSchema}
}

const inventoryReportSchema = {
    base: {
        brands: {title:'Brands', value: null, trend: trendSchema},
        categories: {title:'Categories', value: null, trend: trendSchema},
        subcategories: {title:'Subcategories', value: null, trend: trendSchema},
        sku: {title:'SKUs', value: null, trend: trendSchema},
        skuInStock: {title:'SKUs In Stock', value: null, trend: trendSchema},
        unitsInStock: {title:'Units In Stock', value: null, trend: trendSchema}
    },
    consumption: { avgBurnRate: {title:'Avg Burn Rate', description: 'For the last 90d period', value: null, unit: 'pcs', trend: trendSchema},
        avgBurnRatePeriod: {title:'Avg Burn Rate (Period)', description: 'For the user-selected period', value: null, unit: 'pcs', trend: trendSchema},
        maxDaysLeft: {title:'Max Days Left', value: null, unit: 'days', trend: trendSchema},
        minDaysLeft: {title:'Min Days Left', value: null, unit: 'days', trend: trendSchema},
        projectedSales90d: {title:'Projected Sales', description: 'Based on 90d burn rate stat', value: null, unit: 'pcs', trend: trendSchema},
        projectedSales30d: {title:'Projected Sales (Period)', description: 'Based on user-selected period burn rate stat', value: null, unit: 'pcs', trend: trendSchema},
    },
    sales: {
        revenue: {title:'Total Revenue', unit: '$', value: null, trend: trendSchema},
        pctRevenue: {title:'% of Revenue', value: null, unit: '%', trend: trendSchema},
        netProfit: {title:'Total Net Profit', unit: '$', value: null, trend: trendSchema},
        pctProfit: {title:'% of Net Profit', value: null, unit: '%', trend: trendSchema},
        unitsSold: {title:'Total Units Sold', unit: 'pcs', description: 'for selected time period', value: null, trend: trendSchema},
        pctSales: {title:'% of Sales', value: null, unit: '%', trend: trendSchema},
        pctMargin: {title:'Margin %', value: null, unit: '%', trend: trendSchema}
    }
}

const cleanStatSchema = (dataObject, loading=false) => {
    const resetData = {};

    for (const key in dataObject) {
        resetData[key] = {
            ...dataObject[key],
            value: null, // Reset value to null
            trend: { ...trendSchema }, // Reset trend to its default structure
            loading: loading
        };
    }

    return resetData;
};

const createEmptyInventoryReport = (data, loading=false) => {
    const emptyReport = {};

    emptyReport.base = cleanStatSchema(data.base, loading);
    emptyReport.consumption = cleanStatSchema(data.consumption, loading);
    emptyReport.sales = cleanStatSchema(data.sales, loading);

    return emptyReport;
};

const overviewDataMap = {sales: {endpoint: 'rpc/overview_performance_daily_avg', data: false},
    margin: {endpoint: 'rpc/overview_margin_daily_avg', data: false},
    loyalCustomers: {endpoint: 'rpc/overview_loyal_customers', data: false},
    newCustomers: {endpoint: 'rpc/overview_new_customers', data: false},
    lostCustomers: {endpoint: 'rpc/overview_lost_customers', data: false},
    returnCustomers: {endpoint: 'rpc/overview_returned_customers', data: false},
    labour: {endpoint: 'rpc/overview_labour_daily_avg', data: false}
}

const inventoryDataMap = {
    inventory: {endpoint: 'rpc/overview_inventory', data: false},
    inventoryDistress: {endpoint: 'rpc/overview_distressed_inventory', data: false}
}

const heatMapSchema = {title: 'No Title', xAxis: [], yAxis: [], series: []}

const dayNameMap = {1:"Monday", 2:"Tuesday", 3:"Wednesday", 4:"Thursday", 5:"Friday", 6:"Saturday", 7:"Sunday"}

const pivotedStaffingHeatMaps = {
    avgEmployeePerShift: heatMapSchema,
    txPerEmployee: heatMapSchema,
    minPerTx: heatMapSchema,
    avgTransactions: heatMapSchema
}

const byDayStaffing = {
    1: heatMapSchema,
    2: heatMapSchema,
    3: heatMapSchema,
    4: heatMapSchema,
    5: heatMapSchema,
    6: heatMapSchema,
    7: heatMapSchema,
}

const byPropertyStuffing = {1:false, 2:false, 3:false, 4:false, 5:false, 6:false,7:false}

export {discountBatchDetails, invReportSummary, inventoryReportSchema, inventoryDashboardSchema, inventoryDataMap, overviewDataMap, overviewDashboardSchema,
    trendSchema, statSchema, dataSchema, txRelatedFields, itemRelatedFields, txCategoryFields, txBehaviourFields,
    registerBehaviourFields, heatMapSchema, pivotedStaffingHeatMaps, shiftOptions, dayNameMap, byDayStaffing, byPropertyStuffing,
    cleanStatSchema, createEmptyInventoryReport, cleanDataSchema}