import Field from "./base/Field";
import {useState} from "react";

const SignIn = (props) => {
    const [state, setState] = useState({
        'email': null,
        'password': null
    })

    const handleSignInClick = (e) => {
        e.preventDefault()
        if (state.email && state.password) {
            props.setUserEmailPassword(state.email, state.password);
            document.getElementById('closeModal').click();
        }
    };

    return (
        <div>
            <dialog id="signIn" className="modal">
                <div className="modal-box w-auto">
                    <h3 className="font-bold text-lg">Sign In</h3>
                    <form className="flex-col space-y-3">
                        <div>
                        <Field flexDirection='flex-col' name='email' placeholder='user@email.com' state={state} setState={setState}/>
                        <Field flexDirection='flex-col' name='password' placeholder='lettersNumbersPass' type='password' state={state} setState={setState}/>
                        </div>
                        <button className="btn btn-sm float-right" onClick={handleSignInClick}>Sign In</button>
                    </form>
                </div>
                <form method="dialog" className="modal-backdrop" >
                    <button id='closeModal'>close</button>
                </form>
            </dialog>
        </div>
    )
}

export default SignIn