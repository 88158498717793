import {Link} from "react-router-dom";

const MobileNav = props =>{
    return (
        <div className="navbar bg-base-100 lg:hidden">
            <div className="flex-1">
                <Link to='/' className="btn btn-ghost text-xl">Racetrack</Link>
            </div>
            <div className="flex-none">
                <button className="btn btn-square btn-ghost w-14 h-14">
                    <label htmlFor="my-drawer-2" className="w-full h-full flex items-center justify-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="inline-block w-5 h-5 stroke-current"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </label>
                </button>
            </div>
        </div>
    )
}

export {MobileNav}