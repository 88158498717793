import {ColChart} from "../charts/LineChart";
import React, {useEffect, memo, useState} from "react";
import apiCall from "../base/call";
import {cleanDataSchema, txCategoryFields} from "../../data/chartSchemas";
import PieChart from "../charts/PieChart";
import {groupByaggregateBy, preparePieSum} from "../../utils/dataTransforms";
import {RadioButton} from "../base/Selector";
import Select from "react-select";

const CategorySales = memo(({setAlert, filters, activeTab, auth}) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(txCategoryFields)
    const [filtered, setFiltered] = useState(txCategoryFields)
    const [selectedFilter, setSelectedFilter] = useState('Category')

    const [selectedOptions, setSelectedOptions] = useState([])
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (!activeTab) return  // load only if your tab is active
        console.log('call CS1')

        setOptions([])
        setSelectedOptions([])
        setFiltered(cleanDataSchema(txCategoryFields))
        setData(cleanDataSchema(txCategoryFields))
        setLoading(true)
        let endpoint = selectedFilter === 'Category' ?  'rpc/sp_master_category_sales' : 'rpc/sp_sub_category_sales'
        apiCall(setAlert, endpoint, filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let units = groupByaggregateBy(dt, '_period', '_category_name', '_units_sold' )
                let revenue = groupByaggregateBy(dt, '_period', '_category_name', '_revenue')
                let mixUnits = preparePieSum(units.series, 'Units Sold')
                let mixRevenue = preparePieSum(revenue.series, 'Revenue')
                setData({units, revenue, mixUnits, mixRevenue})

                setOptions(units.series.map(x => ({ 'value':x.name, 'label': x.name })))
                setLoading(false)
            }
        });
        console.log('call CS2')
    }, [filters.filter, selectedFilter, activeTab])

    useEffect(() => {
        console.log('setting filkter')
        if (!data) return
        if (selectedOptions.length === 0) {
            setFiltered(data)
        } else {
            const selectedOptionsArray = selectedOptions.map(x => x.value)
            let filteredData
            const unitSeries = data.units.series.filter( (obj) => selectedOptionsArray.includes(obj.name) )
            const revenueSeries = data.revenue.series.filter( (obj) => selectedOptionsArray.includes(obj.name) )

            const mixUnits = data.mixUnits.data?.filter( (obj) => selectedOptionsArray.includes(obj.name) )
            const mixRevenue = data.mixRevenue.data?.filter( (obj) => selectedOptionsArray.includes(obj.name) )

            filteredData = {
                units: { ...data.units, data: unitSeries, count: mixUnits.length },
                revenue: { ...data.revenue, data: revenueSeries, count: mixUnits.length },
                mixUnits: { ...data.mixUnits, data: mixUnits, count: mixUnits.length },
                mixRevenue: { ...data.mixRevenue, data: mixRevenue, count: mixRevenue.length }
            }
            setFiltered(filteredData)
        }
    }, [selectedOptions.length, selectedFilter, data])

    const handleSelect = event => {
        setSelectedOptions(event)
    }

    if (!activeTab) return null
    console.log('rendering CS')
    return(
        <div className="flex flex-row space-x-4 mt-8">
        <div className="flex-initial w-64 space-y-2">
            <p className="font-sans inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Select Data</p>
            <RadioButton options={['Category', 'Sub-Category']}
                         selected={selectedFilter}
                         setSelected={setSelectedFilter}/>
            {loading ?
            <span className="loading loading-dots loading-xl"></span> :
            <Select
                isMulti
                name="brands"
                options={options}
                value={selectedOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelect}
                closeMenuOnSelect={false}
                menuIsOpen={true}
            />}
        </div>

        <div className="flex-1 min-w-0 grid grid-cols-2">
            <PieChart title={{text: 'Sales Mix (Units)'}}
                      data={filtered.mixUnits}/>
            <PieChart title={{text: 'Sales Mix (Revenue)'}}
                      data={filtered.mixRevenue}/>
            <ColChart title={{text: 'Unit Sales'}}
                      yAxisName='Count'
                      data={filtered.units}/>
            <ColChart title={{text: 'Revenue'}}
                      yAxisName='USD'
                      data={filtered.revenue}/>
        </div>
        </div>
    )
});

export default CategorySales