import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {baseURL} from "./call";

export default class Auth {
    name = null;
    isLoggedIn = false;
    userEmail = null;
    userPassword = null;
    jwtToken = null;
    jwtTokenExpiry = null;

    constructor() {
        //  do not hold token between session and page refresh
        if (!this.isLoggedIn) sessionStorage.removeItem('tk');
    }

    async getUserDetails() {
        // Returns an object with info about the currently logged in user
        if (!this.isAuthenticated()) {
            await this._loginPostRequest().then(resp => {
                return resp;
            });
        }
        return {
            isLoggedIn: this.isLoggedIn,
            jwtToken: this.jwtToken,
            name: this.name || this.userEmail || "Unknown"
        };
    }

    setCredentials(newEmail, newPassword) {
        this.userEmail = newEmail;
        this.userPassword = newPassword;
    }

    async _keepLoggedIn() {
        while (this.isLoggedIn) {
            await this._loginPostRequest()

            let waitTime = this.jwtTokenExpiry * 1000 - Date.now() - 5000  // wait time is 5 seconds less than expiry
            console.log('Renew in', waitTime)
            await new Promise(resolve => setTimeout(resolve, waitTime))
        }
    }

    async login(newEmail, newPassword) {
        console.log('logging in...')
        this.setCredentials(newEmail, newPassword)
        let resp = await this._loginPostRequest()
        if (this.isLoggedIn) {
            this._keepLoggedIn();
            return {'message': 'Success'}
        } else {
            return resp
        }
    }

    logout() {
        // Get rid of the user credentials
        this.userEmail = null;
        this.userPassword = null;
        this.name = null;
        this.isLoggedIn = false;
        this.jwtToken = null;
        this.jwtTokenExpiry = null;
        sessionStorage.removeItem('tk');
    }

    isAuthenticated() {
        // Return true if user is authenticated and jwt is still valid
        let emailRegEx = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // From http://emailregex.com/

        if (
            this.jwtTokenExpiry > Date.now() &&
            emailRegEx.test(this.userEmail) &&
            this.userPassword &&
            this.userPassword !== ""
        ) {
            return true;
        }
    }

    async _loginPostRequest() {
        if (this.userEmail && this.userPassword) {
            const baseUrl = baseURL()
            const uri = `${baseUrl}/api/rpc/login`

            // Makes the HTTP request to obtain JWT token + jwtTokenExpiry + user details
            try {
                let resp = await axios.post(uri, {
                    email: this.userEmail,
                    password: this.userPassword
                });
                let data = resp.data;
                this._setToken(true, data.token);
                return resp;
            } catch (e) {
                this.isLoggedIn = false;
                console.error('ERROR:', e);
                return e
            }
        }
    }

    // Used to set the relevant parts of this class
    _setToken(status, token) {
        const decoded = jwtDecode(token);

        this.isLoggedIn = status;
        this.jwtToken = token;
        this.jwtTokenExpiry = decoded.exp >= 0 ? decoded.exp : Date.now() + 60 * 60 * 1000;
        sessionStorage.setItem('tk', token)
    }

    toString() {
        return JSON.stringify({
            name: this.name,
            isLoggedIn: this.isLoggedIn,
            userEmail: this.userEmail,
            userPassword: this.userPassword,
            jwtToken: this.jwtToken,
            jwtTokenExpiry: this.jwtTokenExpiry
        });
    }
}