import {trendSchema} from "../../data/chartSchemas";
import React from "react";

export const Stat = ({title, description, value, unit, trend=null, loading =false}) => {
    let trendDirection = trend.direction ? '\u2197' : '\u2198'

    let trendValue =  trend.points + trend.unit + ' ' + trendDirection
    let trendStyle = trend.direction ? 'stat-figure text-primary font-semibold text-green-400' : 'stat-figure text-primary font-semibold text-rose-400'

    return (
            <div className="stat border-t">
                {!trend.points | trend.points === 'NaN' ? null : <div className={trendStyle}>{trendValue}</div>}
                <div className="stat-title">{title}</div>
                {
                    loading ?
                    <span className="loading loading-dots loading-xl"></span> :
                    <div className="stat-value">{value === 'NaN' ? null : value}
                    <span className={'text-lg'}>{unit}</span>
                    </div>
                }
                {description ? <div className="stat-desc text-wrap">{description}</div> : null}
            </div>
    )
}
