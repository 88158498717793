import {memo, useEffect, useState} from "react";
import apiCall from "../base/call";
import {
    dayNameMap,
    pivotedStaffingHeatMaps,
    shiftOptions,
    byDayStaffing,
    cleanDataSchema, byPropertyStuffing
} from "../../data/chartSchemas";
import {convertToArrMatrix, pivotMatrixByDay} from "../../utils/dataTransforms";
import HeatMap from "../charts/HeatMapChart";
import Select from "react-select";
import {RadioButton} from "../base/Selector";
import TabsContainer from "../base/Tabs";


const StaffingFilter = props => {

    const handleOrderTypeSelect = event => {
        let value = event.map(x => x.value).join(',')
        props.setOrderTypes(value)
    }
    const handleRegisterNamesSelect = event => {
        let value = event.map(x => x.value).join(',')
        props.setRegisterNames(value)
    }


    return (<div className="flex-initial w-64 space-y-2">
        <RadioButton options={Object.keys(shiftOptions).map(x => x)}
                     selected={props.selectedShift}
                     setSelected={props.setSelectedShift}/>
        <div className="flex-initial w-64 space-y-2">
            <p className="font-sans inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Order Type</p>
            <Select
                isMulti
                name="orderTypes"
                options={[{value:'In Store', label:'In Store'}, {value:'Delivery', label:'Delivery'}]}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleOrderTypeSelect}
                closeMenuOnSelect={false}
            />
        </div>
        <div className="flex-initial w-64 space-y-2">
            <p className="font-sans inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Register Name</p>
            <Select
                isMulti
                name="registerNames"
                options={[{value:'CURBSIDE', label:'CURBSIDE'}, {value:'Register 1', label:'Register 1'}]}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleRegisterNamesSelect}
                closeMenuOnSelect={false}
            />
        </div>
    </div>
    )
}

const StaffingByDay = memo( ({setAlert, filters, activeTab, auth}) => {
    const [day, setDay] = useState(byDayStaffing)

    const [selectedShift, setSelectedShift] = useState('4h Shifts')
    const [orderTypes, setOrderTypes] = useState('')
    const [registerNames, setRegisterNames] = useState('')

    const chartOptions = {
        yAxis: {labels: {format: '{null}'}},
        xAxis: {opposite: true},
        legend: {enabled: false},
        chart: {height: 400, width: 400, marginTop: 80, marginBottom: 0},
        scrollablePlotArea: {}
    }

    useEffect(() => {
        console.log('call ST1')
        if (!activeTab) return

        setDay(cleanDataSchema(byDayStaffing))
        for (const dayNo of Object.keys(day)) {
            let filter = {...filters}
            filter['isodow'] = dayNo
            filter.order_type = orderTypes
            filter.register_name = registerNames
            let endpoint = shiftOptions[selectedShift]
            apiCall(setAlert, endpoint, filter, auth).then(resp => {
                if (resp && resp.data.length > 0) {
                    let yAxis = resp.data.map(x => (x['_hourgroup']))
                    let xAxis = (Object.keys(resp.data[0])).slice(1)
                    let series = convertToArrMatrix(resp.data, xAxis)

                    day[dayNo] = {
                        xAxis: ['Employees on Shift', 'Tx per Employees', 'Avg Minutes per Tx', 'Avg Tx Qty'],
                        yAxis: yAxis,
                        series: series
                    }
                    setDay({...day})
                }
            })
        }
        console.log('call ST2')
    }, [activeTab, filters.filter, orderTypes, registerNames, selectedShift])

    if (!activeTab) return null
    console.log('rendering ST DAY')
    return (
        <div className="flex-1 min-w-0 grid grid-cols-2 lg:grid-cols-3 gap-y-4">
            {/*<h2 className="flex basis-1/8 text-xl font-bold tracking-wide p-4">In-Store</h2>*/}
            <StaffingFilter selectedShift={selectedShift}
                            setSelectedShift={setSelectedShift}
                            setOrderTypes={setOrderTypes}
                            setRegisterNames={setRegisterNames}/>
            {
                Object.keys(day).map(x => (
                    <HeatMap title={{text: dayNameMap[x]}}
                             xAxis={day[x].xAxis}
                             yAxis={day[x].yAxis}
                             data={day[x].series}
                             chartOptions={chartOptions}
                             isActive={true}/>
                ))
            }
        </div>
    )
});

const StaffingByProperty = memo( ({setAlert, filters, activeTab, auth}) => {
    const [dayData, setDayData] = useState(byPropertyStuffing)
    const [selectedShift, setSelectedShift] = useState('4h Shifts')
    const [orderTypes, setOrderTypes] = useState('')
    const [registerNames, setRegisterNames] = useState('')

    const [pivotMatrix, setPivotMatrix] = useState(pivotedStaffingHeatMaps);

    const chartOptions = {
        yAxis: {labels: {format: '{null}'}},
        xAxis: {opposite: true},
        legend: {enabled: false},
        chart: {height: 400, width: 400, marginTop: 80, marginBottom: 0},
        scrollablePlotArea: {}
    }

    useEffect(() => {
        console.log('call ST1')
        if (!activeTab) return

        setDayData(byPropertyStuffing)
        setPivotMatrix(cleanDataSchema(pivotedStaffingHeatMaps))
        for (const dayNo of Object.keys(dayData)) {
            let filter = {...filters}
            filter['isodow'] = dayNo
            filter.order_type = orderTypes
            filter.register_name = registerNames
            let endpoint = shiftOptions[selectedShift]
            apiCall(setAlert, endpoint, filter, auth).then(resp => {
                if (resp && resp.data.length > 0) {
                    dayData[dayNo] = resp.data
                    setDayData({...dayData})
                }
            })
        }
        console.log('call ST2')
    }, [activeTab, filters.filter, orderTypes, registerNames, selectedShift])

    const allDaysUploaded = () => {
        return Object.values(dayData).every(Boolean)
    }

    useEffect(() => {
        setPivotMatrix(cleanDataSchema(pivotedStaffingHeatMaps))
        if (!allDaysUploaded()) return

        let avgEmployeePerShift = pivotMatrixByDay(dayData, '_avg_emp_on_shift', '_hourgroup')
        avgEmployeePerShift.title = 'Employees on Shift'

        let txPerEmployee = pivotMatrixByDay(dayData, '_tx_per_emp', '_hourgroup')
        txPerEmployee.title = 'Transactions per Employees'

        let minPerTx = pivotMatrixByDay(dayData, '_min_per_tx', '_hourgroup')
        minPerTx.title = 'Minutes per Transaction'

        let avgTransactions = pivotMatrixByDay(dayData, '_avg_tx_qty', '_hourgroup')
        avgTransactions.title = 'Avg Transactions Quantity'

        setPivotMatrix({avgEmployeePerShift, txPerEmployee, minPerTx, avgTransactions})

    }, [dayData])  // check if all items of the object are filled with data

    console.log('rendering ST PROPERTY')
    return (
        <div className="flex-1 min-w-0 grid grid-cols-2 lg:grid-cols-3 gap-y-4">
            <StaffingFilter selectedShift={selectedShift}
                            setSelectedShift={setSelectedShift}
                            setOrderTypes={setOrderTypes}
                            setRegisterNames={setRegisterNames}/>
            {
                Object.keys(pivotMatrix).map(x => (
                    <HeatMap title={{text: pivotMatrix[x].title}}
                             xAxis={pivotMatrix[x].xAxis}
                             yAxis={pivotMatrix[x].yAxis}
                             data={pivotMatrix[x].series}
                             chartOptions={chartOptions}
                             isActive={true}/>
                ))
            }
        </div>
    )
    }
);

const StaffingTabs = ({setAlert, filters, auth, activeTab}) => {
    const [lastTab, setLastTab] = useState(0)
    const [activeTabInner, setActiveTabInner] = useState([0])

    if (!activeTab) return null

    return (
        <div className="mt-8">
            <TabsContainer activeTab={activeTabInner} setLastTab={setLastTab} setActiveTab={setActiveTabInner}>
                <StaffingByDay name="Arrange by the Day" activeTab={activeTabInner.includes(0)}
                               setAlert={setAlert} filters={filters} auth={auth}/>
                <StaffingByProperty name="Arrange by the Property" activeTab={activeTabInner.includes(1)}
                                    setAlert={setAlert} filters={filters} auth={auth}/>
            </TabsContainer>
        </div>
    )
}


export default StaffingTabs