import Select from 'react-select'
import {LineChart} from "../charts/LineChart";
import React, {memo, useEffect, useState} from "react";
import apiCall from "../base/call";
import {cleanDataSchema, txCategoryFields} from "../../data/chartSchemas";
import PieChart from "../charts/PieChart";
import {groupByaggregateBy, preparePieSum} from "../../utils/dataTransforms";

const BrandSales = memo(({setAlert, filters, activeTab, auth, lineCharts}) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(txCategoryFields)
    const [filtered, setFiltered] = useState(txCategoryFields)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [options, setOptions] = useState([])


    useEffect(() => {
        if (!activeTab) return
        console.log('call BS1')

        setOptions([])
        setSelectedOptions([])
        setFiltered(cleanDataSchema(txCategoryFields))
        setData(cleanDataSchema(txCategoryFields))
        setLoading(true)
        let endpoint = 'rpc/sp_brand_sales'
        apiCall(setAlert, endpoint, filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                let units = groupByaggregateBy(dt, '_period', '_brand_name', '_units_sold')
                let revenue = groupByaggregateBy(dt, '_period', '_brand_name', '_revenue')

                let mixUnits = preparePieSum(units.series, 'Units Sold')
                let mixRevenue = preparePieSum(revenue.series, 'Revenue')

                console.log('mixUnits', mixUnits)
                setData({units, revenue, mixUnits, mixRevenue})
                setFiltered({units, revenue, mixUnits, mixRevenue})

                setOptions(units.series.map(x => ({ 'value':x.name, 'label': x.name })))
                setLoading(false)
                console.log('call BS2')
            }
        });
    }, [activeTab, filters.filter]);

    useEffect(() => {
        if (selectedOptions.length === 0) {
            setFiltered(data)
        } else {
            const selectedOptionsArray = selectedOptions.map(x => x.value)
            let filteredData = cleanDataSchema(txCategoryFields)
            if (lineCharts === true) {
                const unitSeries = data.units.series.filter((obj) => selectedOptionsArray.includes(obj.name))
                const revenueSeries = data.revenue.series.filter((obj) => selectedOptionsArray.includes(obj.name))
                filteredData = {
                    ...filteredData,
                    units: { ...data.units, series: unitSeries, count: unitSeries.length },
                    revenue: { ...data.revenue, series: revenueSeries, count: revenueSeries.length }
                };
            }
            const mixUnitsData = data.mixUnits.data?.filter((obj) => selectedOptionsArray.includes(obj.name))
            const mixRevenueData = data.mixRevenue.data?.filter((obj) => selectedOptionsArray.includes(obj.name))
            filteredData = {
                ...filteredData,
                mixUnits: { ...data.mixUnits, data: mixUnitsData, count: mixUnitsData.length },
                mixRevenue: { ...data.mixRevenue, data: mixRevenueData, count: mixRevenueData.length }
            }
            setFiltered(filteredData)
        }
    }, [selectedOptions.length])

    const handleSelect = event => {
        setSelectedOptions(event)
    }

    if (!activeTab) return null
    console.log('rendering BS')
    return(
        <div className="flex flex-row space-x-4 mt-8">
            {loading ?
            <span className="loading loading-dots loading-xl"></span> :
            <div className="flex-initial w-64 space-y-2">
                <p className="font-sans pl-2 inline-block text-1xl sm:text-2xl font-bold text-slate-900 tracking-tight dark:text-slate-200">Select Brands</p>
                <Select
                isMulti
                name="brands"
                value={selectedOptions}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelect}
                closeMenuOnSelect={false}
                menuIsOpen={true}
                />
            </div>}
            <div className="flex-1 min-w-0">
                <div className="grid grid-cols-2">
                    <PieChart title={{text: 'Sales Mix (Units)'}}
                              data={filtered.mixUnits}/>
                    <PieChart title={{text: 'Sales Mix (Revenue)'}}
                              data={filtered.mixRevenue}/>
                </div>
                    {lineCharts ?
                        <div className="grid grid-cols-2">
                            <LineChart title={{text: 'Unit Sales'}}
                                    yAxisName='Count'
                                    data={filtered.units}/>
                            <LineChart title={{text: 'Revenue'}}
                            yAxisName='USD'
                            data={filtered.revenue}/>
                        </div>
                    : null}
            </div>
        </div>
    )
});

export default BrandSales